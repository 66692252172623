
import { defineComponent, ref, computed } from '@vue/composition-api';
import { Field } from '../index';

interface OrganizerDoc {
  docDesc: string;
}

interface ParticipantDoc {
  docLink: string;
}

interface DocField extends Field {
  value: ParticipantDoc;
  setup: OrganizerDoc;
}

export default defineComponent({
  name: 'MDocumentation',
  props: {
    fetchProgram: {
      required: false,
      type: [Object, Function],
      default: () => {}
    },
    adkType: {
      required: false,
      type: String,
      default: ''
    },
    userType: {
      required: true,
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(_props, _ctx) {
    const programDocument: any = computed({
      get: () => _props.value,
      set: newVal => {
        _ctx.emit('input', newVal);
      }
    });

    const adkIndex = computed(() => {
      const indx = programDocument.value?.data.adks?.findIndex(adk => {
        if (adk) {
          return adk.name === 'Documentation';
        }
        return false;
      });
      return indx;
    });

    const fieldIndex = computed(() => {
      const Fdindx = programDocument.value?.data?.adks?.[adkIndex.value]?.fields?.findIndex(
        field => {
          if (field.name) {
            return field.name === 'm-documentation';
          }
          return false;
        }
      );
      return Fdindx;
    });

    const fieldValue = computed<DocField>({
      get: () => programDocument.value?.data?.adks?.[adkIndex.value]?.fields[fieldIndex.value],
      set: newVal => {
        if (fieldValue.value)
          programDocument.value.data.adks[adkIndex.value].fields[fieldIndex.value] = newVal;
      }
    });
    const participantValue = computed<ParticipantDoc>({
      get: () => {
        if (!fieldValue.value.value) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          fieldValue.value.value = {
            docLink: ''
          };
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        if (!fieldValue.value.value.docLink) fieldValue.value.value.docLink = '';

        return fieldValue.value.value;
      },
      set: newVal => {
        fieldValue.value.value = newVal;
      }
    });
    const organizerValue = computed<OrganizerDoc>({
      get: () => {
        if (!fieldValue.value.setup) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          fieldValue.value.setup = {
            docDesc: ''
          };
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          if (!fieldValue.value.setup.docDesc) fieldValue.value.setup.docDesc = '';
        }
        return fieldValue.value.setup;
      },
      set: newVal => {
        fieldValue.value.setup = newVal;
      }
    });

    const userIsEmployer = ref(_props.userType === 'organizer');

    const docRegex = /(https:\/\/)docs.google.com\/document\/d\/e\/.*\/pub/;
    const sheetsRegex = /https:\/\/docs\.google\.com\/spreadsheets\/d\/e\/[\w-]*\/pubhtml/;
    const exampleDoc =
      'https://docs.google.com/document/d/e/2PACX-1vQu4kEK2uy9sV70G-kqL6EZ83ucJqJnkIb08YlCyfFVvxaBXRdMA7JctHfVebL0yOOeaMDKuTaKcA64/pub';
    const validators = {
      required: val => !!val || 'Field is required',
      validDocLink: val =>
        !!val.match(docRegex) || !!val.match(sheetsRegex) || 'Invalid Google Docs/Sheets link'
    };

    const isValidDocLink = link => link && (link.match(docRegex) || link.match(sheetsRegex));

    return {
      userIsEmployer,
      docRegex,
      isValidDocLink,
      exampleDoc,
      validators,
      participantValue,
      organizerValue
    };
  }
});
